<template>
  <div :class="{ 'c-dark-theme':  $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 시스템 서비스 설정 --------------------------------------------------------------------------------------------->

    <CCard border-color="info"  style="min-height: 600px">
      <CCardHeader>
        <BRow>
          <BCol>
            <BIconServer class="mr-2"/> 시스템 서비스
          </BCol>
          <BCol class="text-right">
            <BButtonGroup>
              <BButton size="sm" variant="primary" class="mr-1" @click="addService">
                <BIconNodePlusFill/> 서비스 추가
              </BButton>
              <BButton size="sm" variant="primary" type="submit" @click="applyService">
                <BIconBootstrapReboot/> 설정 반영
              </BButton>
            </BButtonGroup>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody>

        <BTable
          small
          bordered
          responsive
          selectable
          select-mode="single"
          selected-variant="danger"
          sticky-header
          sticky-column
          ref="serviceTable"
          thead-class="text-center"
          head-variant="light"
          class="text-nowrap small text-center"
          :fields="serviceFields"
          :items="serviceRows"
          @row-selected="serviceSelected"
        >
          <template #cell(status)="row">
            <BBadge :variant="row.item.status==='listening'?'success':'danger'">{{ row.item.status }}
            </BBadge>
          </template>

        </BTable>

        <BCard class="p-2 small mb-0" v-if="service!==null" border-variant="info" no-body>
          <BForm @submit="serviceSubmit">
            <!-- serviceName -->
            <BFormGroup
              size="sm" class="mb-1"
              label="서비스 명"
              label-for="serviceName"
              label-cols-md="3"
              valid-feedback="사용 가능한 서비스명입니다"
              invalid-feedback="예) ATG 관제 서비스"
              :state="service.name.length > 2"
            >
              <BFormInput size="sm" id="serviceName"
                          v-model="service.name"
                          :state="service.name.length > 2"
                          maxLength="50"
                          required
                          trim/>
            </BFormGroup>
            <!-- serviceDesc -->
            <BFormGroup
              size="sm" class="mb-1"
              label="서비스 설명"
              label-for="serviceDesc"
              label-cols-md="3"
              :state="service.description.length > 2"
            >
              <BFormInput size="sm" id="serviceDesc"
                          v-model="service.description"
                          :state="service.description.length > 2"
                          maxLength="200"
                          trim/>
            </BFormGroup>
            <!-- serviceEnabled -->
            <BFormGroup
              size="sm" class="mb-1"
              label="서비스 사용"
              label-for="svcEnabled"
              label-cols-md="3"
            >
              <BFormCheckbox id="svcEnabled"
                             class="mt-1"
                             v-model="service.enabled" switch>
                {{`${service.enabled?'사용':'사용 중지'}`}}
              </BFormCheckbox>
            </BFormGroup>
            <!-- companyId -->
            <BFormGroup
              size="sm" class="mb-1"
              label="서비스 식별 코드"
              label-for="companyId"
              label-cols-md="3"
              valid-feedback="사용 가능한 코드입니다"
              invalid-feedback="숫자 4자 입력 예) 0009"
              :state="isValidExp(service.companyId, 'num', 4)"
            >
              <BFormInput size="sm" id="companyId"
                          v-model="service.companyId"
                          @input="v=>(service.companyId = v.toUpperCase())"
                          :state="isValidExp(service.companyId, 'num', 4)"
                          maxLength="4"
                          required
                          trim/>
            </BFormGroup>
            <!-- siteId -->
            <BFormGroup
              size="sm" class="mb-1"
              label="사이트 식별 코드"
              label-for="siteId"
              label-cols-md="3"
              valid-feedback="사용 가능한 코드입니다"
              invalid-feedback="16진수 4자 예) 1F30"
              :state="isValidExp(service.siteId, 'hex', 4)"
            >
              <BFormInput size="sm" id="siteId"
                          v-model="service.siteId" :state="isValidExp(service.siteId, 'hex', 4)"
                          @input="v=>(service.siteId = v.toUpperCase())"
                          maxLength="4"
                          required
                          trim/>
            </BFormGroup>
            <!-- serverIp -->
            <BFormGroup
              size="sm" class="mb-1"
              label="서버 IP 주소"
              label-for="serverIp"
              label-cols-md="3"
              valid-feedback="IP 주소 형식입니다"
              invalid-feedback="IP 주소  예) 192.168.10.123"
              :state="isValidExp(service.serverIp, 'ip')"
            >
              <BFormInput size="sm" id="serverIp"
                          v-model="service.serverIp"
                          :state="isValidExp(service.serverIp, 'ip')"
                          maxLength="40"
                          required
                          trim/>
            </BFormGroup>
            <!-- port# -->
            <BFormGroup
              size="sm"  class="mb-1"
              label="서비스 포트 번호"
              label-for="servicePort"
              label-cols-md="3"
              valid-feedback="포트번호 형식입니다."
              invalid-feedback="포트번호 1000 ~ 65535 사이 값"
              :state="service.port > 999 && service.port < 65536"
            >
              <BFormInput size="sm"
                          id="servicePort"
                          name="port"
                          type="number"
                          :state="service.port > 999 && service.port < 65536"
                          v-model="service.port"
              />
            </BFormGroup>
            <!-- timeout -->
            <BFormGroup
              size="sm"  class="mb-1"
              label="서비스 타임아웃"
              label-for="serviceTimeout"
              label-cols-md="3"
              valid-feedback="미리세컨드 숫자 형식입니다."
              invalid-feedback="미리세컨드 입력"
              :state="service.timeout >= 0 && service.timeout < 9999999"
            >
              <BFormInput size="sm"
                          id="serviceTimeout"
                          name="timeout"
                          type="number"
                          :state="service.timeout >= 0 && service.timeout < 9999999"
                          v-model="service.timeout"
              />
            </BFormGroup>
            <!-- gateway -->
            <BFormGroup
              size="sm" class="mb-1"
              label="Gateway IP"
              label-for="gatewayIp"
              label-cols-md="3"
              valid-feedback="IP 주소 형식입니다"
              invalid-feedback="IP 주소  예) 192.168.10.1"
              :state="isValidExp(service.gateway, 'ip')"
            >
              <BFormInput size="sm" id="gatewayIp"
                          v-model="service.gateway"
                          :state="isValidExp(service.gateway, 'ip')"
                          maxLength="40"
                          required
                          trim/>
            </BFormGroup>
            <!-- subnet -->
            <BFormGroup
              size="sm" class="mb-2"
              label="서브넷"
              label-for="subnet"
              label-cols-md="3"
              valid-feedback="서브넷 형식입니다"
              invalid-feedback="서브넷 형식 예) 255.255.255.0"
              :state="isValidExp(service.subnet, 'ip')"
            >
              <BFormInput size="sm" id="subnet"
                          v-model="service.subnet"
                          :state="isValidExp(service.subnet, 'ip')"
                          maxLength="40"
                          required
                          trim/>
            </BFormGroup>
            <!-- status -->
            <BFormGroup
              size="sm" class="mb-2"
              label="현재상태"
              label-for="status"
              label-cols-md="3"
              :state="service.status==='listening'"
            >
              <BFormInput size="sm" id="status"
                          v-model="service.status"
                          :state="service.status==='listening'"
                          readonly/>
            </BFormGroup>
            <hr>
            <!--packetType-->
            <CSelect size="sm" class="mb-1"
                     horizontal
                     label="패킷 방식"
                     :value.sync="service.packetType"
                     :options="packetTypeOpts"
            />
            <!-- encrypted -->
            <BFormGroup
              size="sm" class="mb-1"
              label="패킷 암호화"
              label-for="encrypted"
              label-cols-md="3"
            >
              <BFormCheckbox id="encrypted"
                             class="mt-1"
                             v-model="service.encrypted" switch>
                {{`${service.encrypted?'암호화 수신':'평문 수신'}`}}
              </BFormCheckbox>
            </BFormGroup>

            <div v-show="service.encrypted">
              <!-- encType -->
              <CSelect size="sm" class="mb-1"
                       horizontal
                       label="암호화 방식"
                       :value.sync="service.encType"
                       :options="encTypeOpts"
              />
              <!-- encKey -->
              <BFormGroup
                size="sm" class="mb-1"
                label="패킷 암호 키"
                label-for="encKey"
                label-cols-md="3"
                valid-feedback="암호키 형식입니다"
                invalid-feedback="16진수 32자 이상 예) 0F0E1099887736250F0E109988773625"
              >
                <BFormInput size="sm" id="encKey" type="text"
                            v-model="service.encKey"
                            :state="isValidExp(service.encKey, 'hex', 64)||isValidExp(service.encKey, 'hex', 32)"
                            maxLength="64"
                            :required="service.encrypted"
                            trim/>
              </BFormGroup>
              <!-- encVector -->
              <BFormGroup
                size="sm"
                label="패킷 암호 벡터"
                label-for="siteVector"
                label-cols-md="3"
                valid-feedback="벡터 형식입니다"
                invalid-feedback="16자 예) 0F0E1099887736250F0E109988773625"
                :state="isValidExp(service.vector, 'hex', 32)"
              >
                <BFormInput size="sm" id="siteVector" type="text"
                            v-model="service.vector"
                            :state="isValidExp(service.vector, 'hex', 32)"
                            maxLength="32"
                            :required="service.encrypted"
                            trim/>
              </BFormGroup>

            </div>

            <div class="row justify-content-between p-3">
              <BButtonGroup>
                <BButton type="submit" variant="primary">
                  <BIconSaveFill/> 서비스 설정 저장
                </BButton>
              </BButtonGroup>

              <BButton variant="danger" @click="deleteRecord">
                <BIconTrashFill/>
                삭 제
              </BButton>
            </div>

          </BForm>
        </BCard>
      </CCardBody>
    </CCard>




  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
} from '@/common/utils';


// import qs from 'querystring';
// import moment from "moment";


const _service = {
  code        : '0000',
  companyId   : '0000',
  name        : '신규서비스',
  siteId      : '0000',
  description : '서비스 설명',
  enabled     : true,
  status      : '00',
  gateway     : '127.0.0.1',
  subnet      : '255.255.255.0',
  serverIp    : '127.0.0.1',
  port        : 3000,
  timeout     : 5000,
  packetType  : 'ATG_TCP',
  encrypted   : true,
  encType     : 'ARIA_CBC',
  encKey      : '',
  vector      : '',
};

//----------------------------------------------------------------------------------------------------
export default {
  name: "SystemService",
  components: {

  },
  computed: {},
  data () {
    return {
      service: null,
      serviceRows : [],
      serviceFields : [
        // {key: 'code'       , label: '코드'}, // 서비스 식별 코드
        {key: 'name'       , label: '서비스명'},
        {key: 'companyId'  , label: '식별코드'}, // 서비스 식별 코드
        {key: 'siteId'     , label: '사이트코드'}, // 사이트 식별 코드
        // {key: 'description', label: '설명'},
        {key: 'enabled'    , label: '사용', formatter: (v)=>{return v?'Y':'N'}},
        {key: 'serverIp'   , label: '서버IP'},
        {key: 'port'       , label: '포트#'},
        {key: 'status'     , label: '상태'},
        // {key: 'gateway'    , label: 'G/W IP'},
        // {key: 'subnet'     , label: '서브넷'},
        {key: 'packetType' , label: '패킷형태'},
        {key: 'encrypted'  , label: '암호화', formatter: (v)=>{return v?'Y':'N'}},
        {key: 'encType'    , label: '암호화방식'},
        // {key: 'encKey'     , label: '암호화키'},
        // {key: 'vector'     , label: '벡터'},
        {key: 'regId'      , label: '등록자'},
        {key: 'updId'      , label: '수정자'},
      ],

      // packetTypeMap : this.$store.state.codeMaps['PACKET_TYPE'],
      packetTypeOpts : this.$store.state.codeOpts['PACKET_TYPE'],
      // encTypeMap: this.$store.state.codeMaps['ENC_TYPE'],
      encTypeOpts: this.$store.state.codeOpts['ENC_TYPE'],

    }

  },
  async created(){
    try{
      console.log("--- System Services mounted---------------------");
      await this.getService();
    }catch(err){
      console.log(err);
    }
  },


  mounted() {
    console.log("--- System Services mounted---------------------");
    // below is not work!
  },

  methods: {



    async applyService(){
      try{
        const r = await apiCall('get', `/api/service/apply`);
        await this.toastResult(r, `서비스 변경 정보 시스템 반영`);
        if(r.code===200){
          await this.alertSuccess('시스템에 반영 되었습니다.');
          await this.getService();
        }else{
          await this.alertDanger('시스템 적용 실패', r.code);
        }

      }catch(err){
        await this.alertError(err);
      }
    },

    async getService(){
      try{
        const r = await apiCall('get', `/api/service`);
        if(r.code===200){
          this.serviceRows = r.result;
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },

    async addService(){
      this.service = cloneVar(_service);
      this.service.companyId = this.makeRandom(1000,9999);
      this.service.name = (this.serviceRows.length + 1) + '-신규서비스'
      this.service.port = this.makeRandom(1000, 65535);
      const r = await apiCall('post', `/api/service`, this.service);

      if(r.code===200){
        this.service = r.result;
        await this.getService();

      }
      await this.toastResult(r);
    },

    async serviceSelected(item){
      console.log("------------ serviceSelected ------------", item.length);
      if( item.length === 0 ) {
        this.service = null;
      }else{
        this.service = item.pop();
        console.log( this.service );
      }
    },

    async serviceSubmit(evt){
      evt.preventDefault();
      console.log( 'serviceSubmit------------->', this.service );
      try{
        const r = await apiCall('put', `/api/service/${this.service._id}`, this.service );
        console.log('serviceSubmit------------->' , r);
        if(r.code===200){
          this.service = r.result;
        }

        await this.toastResult(r);
      }catch(err){
        console.log(err);
      }
    },

    async deleteRecord(){
      try{
        const obj = this.service;
        const confirmMsg = `${obj.name} 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;
        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }
        const r = await apiCall('DEL', `/api/service/${obj._id}`);
        // console.log( r );
        if( r.code===200 ){
          await this.getService();
        }else{
          await this.toastInfo('삭제 에러: '+r.message, 'Error', 'danger' );
        }
      }catch(err){
        await this.alertError(err);
      }
    },


  }
}
</script>
