<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 유종 리스트 ------------------------------------------------------>

    <CCard border-color="info">
      <CCardHeader>
        <BRow>
          <BCol>
            <BIconSegmentedNav/> 백업 관리
          </BCol>
          <BCol class="text-right">
            <BButtonGroup>
              <BButton variant="primary" class='mr-1' @click="updateMeta" :disabled="isBackingUp"><BIconArrowRepeat/> <b>정보 갱신</b></BButton>
              <BButton variant="warning" class='mr-1' @click="setPacketControl('Y')" :disabled="isBackingUp"><b>트레픽 허용</b></BButton>
              <BButton variant="danger" class='mr-1' @click="setPacketControl('N')" :disabled="isBackingUp"><b>트레픽 중지</b></BButton>
              <BButton variant="dark" @click="dbBackup" :disabled="isBackingUp">
                <b-spinner small label="Spinning" v-if="isBackingUp"/> <b>백업실행</b>
              </BButton>
            </BButtonGroup>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody>

        <BTable
          small
          responsive
          selectable
          select-mode="single"
          selected-variant="primary"
          sticky-header
          sticky-column
          ref="table"
          head-variant="light"
          class="text-nowrap small"
          :fields="fields"
          :items="rows"
          @row-selected="rowSelected"
        >

          <template #cell(isBackup)="{item}">
            <BFormCheckbox v-model="item.isBackup" class="text-center" switch @input="updateBackup(item)"/>
          </template>
          <template #cell(download)="{item}">
            <BBadge class="font-sm"
                     variant="info"
                     v-show="item.backupFile"
                     @click="downloadFile(item)"
                     size="sm">
              <BIconDownload/>
            </BBadge>
          </template>

        </BTable>

        <CCard v-if="row" accent-color="info">
          <BCardHeader>
            <BBadge variant="info"><b>{{row.tableName}}</b></BBadge> 상세정보
          </BCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <BForm @submit="formSubmit">
                  <BFormGroup
                    size="sm"
                    label="테이블명"
                    label-for="tableName"
                    label-cols-md="3"
                  >
                    <BFormInput size="sm"
                                id="tableName"
                                v-model="row.tableName"
                                readonly/>
                  </BFormGroup>

                  <BFormGroup
                    size="sm"
                    label="테이블 설명"
                    label-for="desc"
                    label-cols-md="3"
                    maxLength="100"
                  >
                    <BFormInput size="sm"
                                id="desc"
                                v-model="row.description"
                                maxLength="100"
                                trim/>
                  </BFormGroup>

                  <BFormGroup
                    size="sm"
                    label="DB Backup"
                    label-for="isBackup"
                    label-cols-md="3"
                  >
                    <CSelect size="sm"
                             id="isBackup"
                             horizontal
                             :value.sync="row.isBackup"
                             :options="[{value:true, label: 'Backup 실행'},{value:false, label: '없음'} ]"
                             required
                    />
                  </BFormGroup>

                  <BFormGroup
                    size="sm"
                    label="데이터 일시"
                    label-for="backupFromDt"
                    label-cols-md="3"
                    maxLength="50"
                  >
                    <BFormInput size="sm"
                                type="date"
                                id="backupFromDt"
                                v-model="row.backupFromDt"
                                />
                  </BFormGroup>

                  <BRow>
                    <BCol class="text-right">
                      <BButton type="submit"
                               variant="primary"
                               :disabled="isSubmitting">
                        <div class="sk-plane bg-light float-left"
                             style="height:25px;width:25px"
                             v-if="isSubmitting"/>
                        <BIconSaveFill class="mr-1"/> <b>저 장</b>
                      </BButton>
                    </BCol>
                  </BRow>
                </BForm>
              </CCol>

              <CCol>

              </CCol>
            </CRow>


          </CCardBody>
        </CCard>


      </CCardBody>
    </CCard>




  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  alertConfirm,
  apiCall,
} from '@/common/utils';
// import moment from "moment/moment";



//----------------------------------------------------------------------------------------------------
export default {
  name: "SystemManage",
  components: {
  },
  props: {
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      pickerShow: false,
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      isCreateMode: false,
      isBackingUp: false,
      rows: [],
      fields: [
        { key: 'tableName'     ,  sortable: true, tdClass: 'text-left font-weight-bold', label:'테이블명'},
        { key: 'description'   ,  sortable: true, label:'테이블 설명'},
        { key: 'rowCount'      ,  sortable: true, label:'레코드 수', tdClass: 'text-right'},
        { key: 'countAt'       ,  sortable: true, label:'카운트 일시', formatter: (v)=>{ return this.toLocalTime(v) }},
        { key: 'isBackup'      ,  sortable: true, label:'백업여부', formatter: (v)=>{ return v? 'Y': 'N'} },
        { key: 'backupFile'    ,  sortable: true, label:'백업경로', tdClass: 'text-left'},
        { key: 'backupFromDt'  ,  sortable: true, label:'기준일시(From)', formatter: (v)=>{ return this.toLocalTime(v) }},
        { key: 'backupAt'      ,  sortable: true, label:'백업 일시', formatter: (v)=>{ return this.toLocalTime(v) }},
        { key: 'backupCount'   ,  sortable: true, label:'백업 레코드 수', tdClass: 'text-right'},
        { key: 'download'      ,  sortable: true, label:'다운로드', tdClass: 'text-center'},
        { key: 'downCount'     ,  sortable: true, label:'다운로드수', tdClass: 'text-right'},
        { key: 'updId'         ,  sortable: true, label:'수정자'},
        { key: 'updatedAt'     ,  sortable: true, label:'수정일', formatter: (v)=>{ return this.toLocalTime(v) }},
        { key: 'regId'         ,  sortable: true, label:'등록자'},
        { key: 'createdAt'     ,  sortable: true, label:'등록일', formatter: (v)=>{ return this.toLocalTime(v) }},
      ],

      formShow: false,
      isSubmitting: false,
      row: null,
    }

  },
  async created(){
    try{
      await this.getMetaList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- OilList mounted---------------------");
    // below is not work!
  },

  methods: {
    async updateMeta(){
      try{
        this.rows = [];
        this.row = null;
        const r = await apiCall('put', `/api/meta/update` );
        if( r.code === 200 )  this.rows = r.result;
        await this.toastResult( r );
      }catch(err){
        console.error(err);
      }
    },

    async setPacketControl(packetYn){
      try{
        console.log("setPacketControl ----------------->", packetYn);
        if(packetYn==='N'){
          const confirmMsg = `센서 트레픽 유입이 중단됩니다. 진행 하시겠습니까?`;
          if (!(await alertConfirm(this.$bvModal, confirmMsg, '트레픽 유입 중단'))) {
            return;
          }
        }


        const r = await apiCall('post', `/iot/control`, {packetYn: packetYn} );
        await this.toastResult( r );
      }catch(err){
       console.error(err);
      }
    },

    async getMetaList(){
      try{
        console.log( "getMetaList() --- query-string ----> ");
        this.rows = [];
        this.row = null;
        const r = await apiCall('get', `/api/meta`);
        if(r.code===200){
          this.rows = r.result;
        }
        await this.toastResult(r);
        this.selectedTank = null;

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },
    rowSelected(item){
      console.log("------------ screenRowSelected ------------", item.length);
      if( item.length === 0 ) {
        this.row = null;
      }else{
        this.row = item.pop();
      }
    },
    async updateBackup(item){
      console.log('item----------->', item);
      try{
        const r = await apiCall("PUT", `/api/meta/${item._id}`, {isBackup: item.isBackup});
        await this.toastResult( r, '백업 정보 변경');
      }catch(err){
        console.error(err);
      }
    },

    async formSubmit(evt){
      let r;
      evt.preventDefault();

      try{
        this.isSubmitting = true;

        r = await apiCall("PUT", `/api/meta/${this.row._id}`, this.row);

        console.log( r );
        if(r.code===200){
          await this.alertSuccess('DB 메타정보가 저장 되었습니다.', '시스템 관리');
          await this.getMetaList();
        }else{
          await this.toastResult( r, '시스템 관리 정보 저장')
        }
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.error( err );
      }finally{
        this.isSubmitting = false;
        this.isCreateMode = false;
      }
    },
    async dbBackup(){
      let r;
      try{
        this.isBackingUp = true;
        r = await apiCall("POST", `/api/meta/backup`);
        console.log( r );
        if(r.code===200){
          this.isBackingUp = false;
          await this.alertSuccess('DB백업 성공! 파일을 다운로드 할 수 있습니다.', '시스템 관리');
          this.rows = r.result;
        }else{

          await this.toastResult( r, '시스템 관리 - DB 백업');
        }
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.error( err );
      }finally{
        this.isBackingUp = false;
      }
    },
    async downloadFile(item){
      console.log('downloadFile ---->', item)

      try{
        const r = await apiCall('getfile', `/api/meta/download/${item._id}`);
        const fileName = item.backupFile.split('/').pop();
        await this.getDownload(fileName, r);

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }

    }


  } // end of methods
}
</script>
